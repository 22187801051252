<template>
    <div>Recetas</div>
</template>

<script>
export default {

}
</script>

<style>

</style>